import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//API
import { apiRest } from '../../api/services';

//Componentes
import { UIBTNSecondary } from '../../ui';

import './details.css';

function DetailsFacture() {
    const navigate = useNavigate();
    const { id } = useParams();
    const userAuth = useSelector(state => state.authentication);
    const [dataFacture, setDataFacture] = useState();

    useEffect(() => {
        apiRest
            .getFacture(id, userAuth.token)
            .then((res) => {
                setDataFacture(res)
                //console.log(res)
            })
    }, [id, userAuth.token])

    return (
        <div id="app" class="col-11">

            <h2>Factura</h2>

            <div class="row my-3">
                <div class="col-10">
                    <h1>{dataFacture?.ref_user_camion?.refEmissionPoint?.name}</h1>
                    <p>Dirección: {dataFacture?.ref_user_camion?.refEmissionPoint?.address}</p>
                    <p>Telef: {dataFacture?.ref_user_camion?.refEmissionPoint?.telef}</p>
                </div>
                <div class="col-2">
                    <img src="" alt='Logo negocio' />
                </div>
            </div>

            <hr />

            <div class="row fact-info mt-3">
                <div class="col-3">
                    <h5>Facturar a</h5>
                    <p>
                        {dataFacture?.ref_user?.name} {dataFacture?.ref_user?.lastName}
                    </p>
                </div>
                <div class="col-3">
                    <h5>Enviar a</h5>
                    <p>
                        {dataFacture?.ref_user?.dir?.address}
                        {dataFacture?.ref_user?.dir?.ref_dir}
                    </p>
                </div>
                <div class="col-3">
                    <h5>Email</h5>
                    <p>
                        {dataFacture?.ref_user?.email}
                    </p>
                </div>
                <div class="col-3">
                    <h5>N° de factura</h5>
                    <h5>Fecha: {dataFacture?.fecha}</h5>
                </div>
            </div>

            <div class="row my-5">
                <table class="table table-borderless factura">
                    <thead>
                        <tr>
                            <th>Cant.</th>
                            <th>Descripcion</th>
                            <th>Precio Unitario</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{dataFacture?.cantidadCilindro}</td>
                            <td>{dataFacture?.tipoCilindro} color {dataFacture?.colorCilindro}</td>
                            <td>{dataFacture?.valorUnitario}</td>
                            <td>{dataFacture?.precioTotal}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Total Factura</th>
                            <th>{dataFacture?.precioTotal}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div class="cond row">
                <div class="col-12 mt-3">
                    <h4>Condiciones y formas de pago</h4>
                    <p>El pago se debe realizar en un plazo de 15 dias.</p>
                    <p>
                        Banco Banreserva
                        <br />
                        IBAN: DO XX 0075 XXXX XX XX XXXX XXXX
                        <br />
                        Código SWIFT: BPDODOSXXXX
                    </p>
                </div>
            </div>

            <div>
                <UIBTNSecondary
                    label="Atras"
                    action={() => navigate('/admin/factures')}
                />
                <UIBTNSecondary
                    label="Editar"
                    action={() => navigate(`/admin/facture/${dataFacture._id}/edit`, {dataFacture})}
                />
            </div>
        </div>
    )
}

export default DetailsFacture