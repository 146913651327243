import { Outlet } from 'react-router-dom'

//component
import SideBar from '../component/sidebar/sidebar'
import Header from '../component/header/header'

function MainLayout() {
    return (
        <div className="app">
            <header className="app-header">
                <Header />
            </header>
            <nav className="app-sideBar">
                <SideBar />
            </nav>
            <main className="app-main">
                <Outlet />
            </main>
        </div>
    )
}

export default MainLayout