import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiRest } from '../../api/services';
import { UIBTNPrimary, UITextInput } from '../../ui';


function EditEstab() {
    const token = useSelector(state => state.authentication.token)
    const navigate = useNavigate();
    const { id } = useParams();

    const [input, setInputs] = useState({
        name: '',
        address: '',
        telef: '',
        codEst: ''
    });

    useEffect(() => {
        apiRest
            .getEstablishment(id, token)
            .then((resp) => {
                setInputs({
                    name: resp[0].name,
                    address: resp[0].address,
                    telef: resp[0].telef,
                    codEst: resp[0].codEst
                })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }, [id, token])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const saveEstab = (e) => {
        e.preventDefault();
        apiRest
            .putEstablishment(id, input, token)
            .then((resp) => {
                toast(resp.message, { onClose: () => navigate('/admin/establishment') })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }

    return (
        <>
            <div>
                Editar establecimiento
            </div>
            <form name="createEstab" onSubmit={saveEstab}>
                <UITextInput
                    name="name"
                    placeholder="Nombre"
                    value={input.name}
                    onChange={handleChange}
                />
                <UITextInput
                    name="address"
                    placeholder="Dirección establecimiento"
                    value={input.address}
                    onChange={handleChange}
                />
                <UITextInput
                    name="telef"
                    placeholder="Teléfono establecimiento"
                    value={input.telef}
                    type="number"
                    onChange={handleChange}
                />

                <UIBTNPrimary label="Aceptar" />
            </form>
        </>
    )
}

export default EditEstab