import React from "react";
import { Navigate, Outlet } from "react-router-dom";


const PrivateRoute = ({ isAuth, children }) => {
  if (!isAuth) {
    return <Navigate to='/login' replace />
  }

  return children ? children : <Outlet />
}

export default PrivateRoute;