import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// API
import { apiRest } from '../../api/services';
import { useNavigate } from 'react-router-dom';

function Register() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = handleSubmit((data) => {
        apiRest
            .register(data)
            .then((msg) => {
                toast(msg.message, {
                    onClose: () => msg?.resRegistro ? navigate('/login') : ''
                })
            })
            .catch((e) => {
                console.log(e)
                toast(e, { type: 'error' })
            })
    })

    return (
        <div className="loginRegister">
            <div className="loginRegister__body">
                <div className="loginRegister__header">
                    <img src="images/entre-GAS-login.svg" alt="Logo" /> {/* Ajusta el logo según sea necesario */}
                </div>
                <div className="loginRegister__article">
                    <div className="loginRegister__register">
                        <h2>Registro</h2>
                        <form onSubmit={onSubmit}>
                            <div className="textInput">
                                <label htmlFor="ruc">RUC</label>
                                <input type='text' id="ruc" {...register('ruc', { required: true })} />
                                {errors.ruc && <p>RUC requerido.</p>}
                            </div>

                            <div className="textInput">
                                <label htmlFor="name">Nombre</label>
                                <input type='text' id="name" {...register('name', { required: true })} />
                                {errors.name && <p>Nombre requerido.</p>}
                            </div>

                            <div className="textInput">
                                <label htmlFor="lastName">Apellidos</label>
                                <input type='text' id="lastName" {...register('lastName', { required: true })} />
                                {errors.lastName && <p>Apellidos requeridos.</p>}
                            </div>

                            <div className="textInput">
                                <label htmlFor="email">Email</label>
                                <input type='text' id="email" {...register('email', { required: true })} />
                                {errors.email && <p>Email requerido.</p>}
                            </div>

                            <div className="textInput">
                                <label htmlFor="passw">Contraseña</label>
                                <input id="passw" type="password" {...register('passw', { required: true })} />
                                {errors.passw && <p>Contraseña requerida.</p>}
                            </div>

                            <button type="submit">Registrar</button>
                        </form>
                    </div>
                </div>
                <div className="loginRegister__footer">
                    <p>
                        <a href='/login'>Ya tengo cuenta</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Register;