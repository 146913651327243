import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import { useSelector } from 'react-redux';

//pages public
import Login from './login/login';
import Register from './registrarse/register';
import PageNotFound from './PageNotFound';
import PageHome from './home/pageHome';

//RUTA PROTEGIDA
import PrivateRoute from '../helpers/isAuth';
//pages protected
import MainLayout from '../layout';
import Main from './main/main';
import ListUser from './user/listUser';
import CreateTransport from './user/createTransport';
import EditUser from './user/editUser';
import MyData from './user/myData';
import ListEstablishment from './establishment/establishment';
import CreateEstablishment from './establishment/createEstab';
import EditEstab from './establishment/editEstab';
import EmissionPoint from './emisionPoint/emissionPoint';
import CreateEPoint from './emisionPoint/createEPoint';
import EditEmissionPoint from './emisionPoint/editEmissionPoint';
import Factures from './factures/factures';
import DetailsFacture from './factures/details';
import EditFacture from './factures/editFacture';
import Products from './products/products';
import CreateProduct from './products/createProduc';

function Rootpages() {

    const userState = useSelector((state) => state.authentication);

    return (
        <Routes>
            <Route path='/' element={<PageHome />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route element={<PrivateRoute isAuth={userState?.loggedIn} />}>
                <Route path='/admin' element={<MainLayout />}>
                    <Route index element={<Main />} />

                    <Route path='user' element={<ListUser />} />
                    <Route path='user/create' element={<CreateTransport />} />
                    <Route path='user/:id/edit' element={<EditUser />} />
                    <Route path='user/my-data' element={<MyData />} />

                    <Route path='establishment' element={<ListEstablishment />} />
                    <Route path='establishment/create' element={<CreateEstablishment />} />
                    <Route path='establishment/:id/edit' element={<EditEstab />} />

                    <Route path='emission-point' element={<EmissionPoint />} />
                    <Route path='emission-point/create' element={<CreateEPoint />} />
                    <Route path='emission-point/:id/edit' element={<EditEmissionPoint />} />

                    <Route path='factures' element={<Factures />} />
                    <Route path='facture/:id/details' element={<DetailsFacture />} />
                    <Route path='facture/:id/edit' element={<EditFacture />} />

                    <Route path='product' element={<Products />} />
                    <Route path='product/create' element={<CreateProduct />} />
                    <Route path='product/:id/edit' element={<CreateProduct />} />

                </Route>
            </Route>
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    )
}

export default Rootpages