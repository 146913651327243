import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

import { UITextInput, UISelectOptions, UIBTNPrimary } from '../../ui';
import { apiRest } from '../../api/services';

function CreateTransport() {
    const token = useSelector(state => state.authentication.token);
    const navigate = useNavigate();

    const [listEP, setListEP] = useState([]);
    const [input, setInputs] = useState({
        name: '',
        lastName: '',
        identification: '',
        identificationType: '',
        email: '',
        passw: '',
        chapa: '',
        refEmissionPoint: ''
    });


    useEffect(() => {
       apiRest
        .getEmisionPoints(token)
        .then((resp)=>{
            let rows = resp.map((item) => (
                {
                    id: item._id,
                    name: item.name,
                    value: item._id
                }
            ))
            rows.unshift({name: 'Selecciona...', value: ''});
            setListEP(rows)
        })
    }, [token])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const createTransport = (e) => {
        e.preventDefault();
        apiRest
            .registerTransport(input, token)
            .then((data) => {
                toast(data.message, { onClose: () => navigate('/admin/user') })
            })
            .catch((e) => {
                toast(e, { type: 'error' })
            })
    }

    return (
        <div>
            <h3> Crear usuario tipo: Transportista</h3>
            <form name="regTransportista" onSubmit={createTransport}>
                <UITextInput
                    placeholder="Nombre"
                    name="name"
                    IconSRC="/images/user_icon.svg"
                    value={input.name}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Apellidos"
                    name="lastName"
                    IconSRC="/images/user_icon.svg"
                    value={input.lastName}
                    onChange={handleChange}
                />
                <UISelectOptions
                    name="identificationType"
                    label="Tipo de identificacion"
                    options={[
                        { 'name': 'Pasaporte', 'value': '06' },
                        { 'name': 'Cedula', 'value': '05' },
                        { 'name': 'RUC', 'value': '04' }
                    ]}
                    onChange={handleChange}
                />
                <UITextInput
                    placeholder="Identificación"
                    name="identification"
                    value={input.identification}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Email"
                    name="email"
                    value={input.email}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Contraseña"
                    name="passw"
                    value={input.passw}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Chapa del camión"
                    name="chapa"
                    value={input.chapa}
                    onChange={handleChange}
                />

                <h4>Punto de emision al que va a pertenecer</h4>
                <UISelectOptions
                    name="refEmissionPoint"
                    label="Punto"
                    options={listEP}
                    onChange={handleChange}
                />
                <UIBTNPrimary
                    label="Aceptar"
                />
            </form>
        </div>
    )
}

export default CreateTransport
