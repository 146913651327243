import React, { useState } from "react";
const UIBTNCircle = (props) => {
  const { ImageUP, ImageDown } = props;
  const [show, setShow] = useState(true);

  return (
    <>
      <div className="UIBTNCircle" onClick={() => setShow(!show)}>
        {show ? (
          <img className="UIBTNCircle__img" src={ImageUP} />
        ) : (
          <img className="UIBTNCircle__img" src={ImageDown} />
        )}
      </div>
    </>
  );
};

export default UIBTNCircle;
