const UIDataUser = (props) => {
  const { UserName, UserMail, IMGUser } = props;

  return (
    <>
      <div className="UIDataUser">
        <h3>{UserName}</h3>
        <h5>{UserMail}</h5>
        <img src={IMGUser} alt="user entre-GAS" />
      </div>
    </>
  );
};

export default UIDataUser;
