import { toast } from 'react-toastify';
import { apiRest } from '../../api/services';
import { userConstants } from './types'

const login = (data, navigate) => {
  
    return async dispatch => {

        apiRest.login(data.userName, data.password, 0)
            .then(
                user => {
                    if (user.loggedIn === false) {
                        dispatch(failure(user.message));

                        toast(user.message, { type: 'error' })
                    }

                    if (user.loggedIn === true) {
                        dispatch(success(user));
                        navigate("/admin")
                    }
                },
                error => {
                    dispatch(failure(error.toString()));

                    toast(error.toString(), { type: 'error' })
                }
            );
            
    };

    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

}

function logout() {
    return { type: userConstants.LOGOUT };
}


export const userActions = {
    login,
    logout
}