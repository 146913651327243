import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom';

//api
import { apiRest } from '../../api/services'
//UI
import { UIBTNPrimary, UISelectOptions, UITextInput } from '../../ui';

function CreateProduct() {
    const navigate = useNavigate();
    const { id } = useParams();
    const token = useSelector(state => state.authentication.token)
    const [input, setInputs] = useState({
        codigoPrincipal: '',
        codigoAuxiliar: '',
        name: '',
        valorUnitario: '',
        impuestoPorCiento: '',
        impuestoCodigo: '',
        infAdicional: ''

    });
    const [listNameProd, setListNameProd] = useState([])

    useEffect(() => {
        if (id) {
            apiRest
                .getProductsId(id, token)
                .then((resp) => {
                    setInputs({
                        codigoPrincipal: resp?.codigoPrincipal,
                        codigoAuxiliar: resp?.codigoAuxiliar,
                        name: resp?.name?._id,
                        valorUnitario: resp?.valorUnitario,
                        impuestoPorCiento: resp?.impuesto?.porCiento,
                        impuestoCodigo: resp?.impuesto?.codigo,
                        infAdicional: resp?.infAdicional
                    })
                })
        }

        apiRest
            .getProductsName(token)
            .then((resp) => {
                let rows = resp.map((item) => (
                    {
                        id: item._id,
                        name: item.name,
                        value: item._id
                    }
                ))
                rows.unshift({ name: 'Selecciona...', value: '' });
                setListNameProd(rows);

            })
    }, [token, id])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const createProd = (e) => {
        e.preventDefault();

        apiRest
            .addProductData(input, token)
            .then((resp) => {
                toast(resp.message, {
                    onClose: () => navigate('/admin/product')
                })
                setInputs({
                    codigoPrincipal: '',
                    codigoAuxiliar: '',
                    name: '',
                    valorUnitario: '',
                    impuestoPorCiento: '',
                    impuestoCodigo: '',
                    infAdicional: ''
                })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })

    }

    const updateProduct = (e) => {
        e.preventDefault();

        apiRest
            .putProductsId(id, input, token)
            .then((resp) => {
                toast(resp.message, {
                    onClose: () => navigate('/admin/product')
                })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }

    return (
        <>
            <div>
                Seccion para completar los parametros de los productos - GAS
            </div>
            <form name="createEditProd" onSubmit={id ? updateProduct : createProd}>

                <UISelectOptions
                    name="name"
                    label="Tipo de GPL"
                    options={listNameProd}
                    value={input.name}
                    onChange={handleChange}
                />
                <UITextInput
                    name="codigoPrincipal"
                    placeholder="Codigo Principal"
                    value={input.codigoPrincipal}
                    onChange={handleChange}
                />
                <UITextInput
                    name="codigoAuxiliar"
                    placeholder="Codigo Auxiliar"
                    value={input.codigoAuxiliar}
                    type="number"
                    onChange={handleChange}
                />
                <UITextInput
                    name="valorUnitario"
                    placeholder="Valor Unitario"
                    value={input.valorUnitario}
                    onChange={handleChange}
                />
                <UITextInput
                    name="impuestoPorCiento"
                    placeholder="Valor impuesto %"
                    value={input.impuestoPorCiento}
                    onChange={handleChange}
                />

                <UITextInput
                    name="impuestoCodigo"
                    placeholder="Valor impuesto codigo"
                    value={input.impuestoCodigo}
                    onChange={handleChange}
                />

                <UITextInput
                    name="infAdicional"
                    placeholder="Informacion adicional"
                    value={input.infAdicional}
                    onChange={handleChange}
                />

                <UIBTNPrimary label="Aceptar" />
            </form>
        </>
    )
}

export default CreateProduct
