import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiRest } from '../../api/services';
import { UIBTNPrimary, UITextInput, UISelectOptions } from '../../ui';

function EditEmissionPoint() {
    const token = useSelector(state => state.authentication.token)
    const navigate = useNavigate();
    const { id } = useParams();
    const [input, setInputs] = useState({
        name: '',
        address: '',
        telef: '',
        codEmissionPoint: '',
        refEstablishment: '',
        refUser: ''
    });
    const [listEstab, setlistEstab] = useState([])


    useEffect(() => {
        apiRest
            .getEstablishments(token)
            .then((resp) => {
                let rows = resp.map((item) => (
                    {
                        id: item._id,
                        name: item.name,
                        value: item._id
                    }
                ))
                rows.unshift({ name: 'Selecciona...', value: '' });
                setlistEstab(rows);
            })

        apiRest
            .getEmisionPoint(id, token)
            .then((res) => {
                console.log(res)
                setInputs({
                    name: res[0].name,
                    address: res[0].address,
                    telef: res[0].telef,
                    codEmissionPoint: res[0].codEmissionPoint,
                    refEstablishment: res[0].refEstablishment,
                    refUser: res[0].refUser
                })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }, [token, id])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const EditEstab = (e) => {
        e.preventDefault();
        apiRest
            .putEmissionPoint(id, input, token)
            .then((res) => {
                toast(res.message, { onClose: () => navigate('/admin/emission-point') })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }

    return (
        <>
            <div>
                Crear establecimientos
            </div>
            <form name="createEstab" onSubmit={EditEstab}>
                <UISelectOptions
                    name="refEstablishment"
                    label="Nombre del Establecimiento al que pertenece"
                    options={listEstab}
                    value={input.refEstablishment}
                    onChange={handleChange}
                />

                <UITextInput
                    name="name"
                    placeholder="Nombre"
                    value={input.name}
                    onChange={handleChange}
                />
                <UITextInput
                    name="address"
                    placeholder="Dirección establecimiento"
                    value={input.address}
                    onChange={handleChange}
                />
                <UITextInput
                    name="telef"
                    placeholder="Teléfono establecimiento"
                    value={input.telef}
                    type="number"
                    onChange={handleChange}
                />

                <UIBTNPrimary label="Aceptar" />
            </form>
        </>
    )
}

export default EditEmissionPoint