const UITextInput = (props) => {
  const { placeholder, IconSRC, type, value, onChange, name, required } = props;

  return (
    <div className="TextInput">
      {IconSRC ? <img alt="icon" src={IconSRC} /> : ""}
      <input
        value={value}
        type={type}
        onChange={onChange}
        name={name}
        required={required || true}
        className="TextInput--Input"
        placeholder=" "
      /* placeholder=" "  dejar espacio dentro de las comillas*/
      />
      <span class="TextInput--Label">{placeholder}</span>
    </div>
  );
};

export default UITextInput;
