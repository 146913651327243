import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//UI
import { UIBTNPrimary, UITextInput } from '../../ui'

//api
import { apiRest } from '../../api/services';

function CreateEstablishment() {
    const navigate = useNavigate();
    const token = useSelector(state => state.authentication.token)
    const [input, setInputs] = useState({
        name: '',
        address: '',
        telef: ''
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const createEstab = (e) => {
        e.preventDefault();
        apiRest
            .createEstablishment(input, token)
            .then((resp) => {
                toast(resp.message, {
                    onClose: () => navigate("/admin/establishment")
                });
                setInputs({
                    name: '',
                    address: '',
                    telef: ''
                })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }

    return (
        <>
            <div>
                Crear establecimientos
            </div>
            <form name="createEstab" onSubmit={createEstab}>
                <UITextInput
                    name="name"
                    placeholder="Nombre"
                    value={input.name}
                    onChange={handleChange}
                />
                <UITextInput
                    name="address"
                    placeholder="Dirección establecimiento"
                    value={input.address}
                    onChange={handleChange}
                />
                <UITextInput
                    name="telef"
                    placeholder="Teléfono establecimiento"
                    value={input.telef}
                    type="number"
                    onChange={handleChange}
                />

                <UIBTNPrimary label="Aceptar" />
            </form>
        </>
    )
}

export default CreateEstablishment
