import React, { useState, useEffect } from 'react';

function CountDownTimer({ minutes, hours, seconds }) {
  const [timeLeft, setTimeLeft] = useState({
    days: 20,
    hours: hours || 0,
    minutes: minutes || 0,
    seconds: seconds || 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const { days, hours, minutes, seconds } = timeLeft;

      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(interval);
        // Aquí puedes manejar lo que sucede cuando el temporizador llega a cero
        console.log('¡Tiempo agotado!');
      } else {
        setTimeLeft(prevTime => {
          const newTime = { ...prevTime };

          if (newTime.seconds > 0) {
            newTime.seconds -= 1;
          } else {
            if (newTime.minutes > 0) {
              newTime.minutes -= 1;
              newTime.seconds = 59;
            } else {
              if (newTime.hours > 0) {
                newTime.hours -= 1;
                newTime.minutes = 59;
                newTime.seconds = 59;
              } else {
                newTime.days -= 1;
                newTime.hours = 23;
                newTime.minutes = 59;
                newTime.seconds = 59;
              }
            }
          }

          return newTime;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  return (
    <div>
      <p>{`${timeLeft.days} días ${timeLeft.hours} horas ${timeLeft.minutes} minutos ${timeLeft.seconds} segundos`}</p>
      {/* Aquí puedes mostrar el temporizador en tu interfaz */}
    </div>
  );
}

const timerStyle = {
  fontSize: '24px',
  backgroundColor: '#002e43',
  color: 'white',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center', // Centrar horizontalmente
  alignItems: 'center', // Centrar verticalmente
  height: '100vh', // Altura de la pantalla completa
};

export default function Home() {
  return (
    <div style={timerStyle}>
      <h1>Tiempo restante</h1>
      <CountDownTimer days={20} />
    </div>
  );
}
