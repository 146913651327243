import React from 'react';
import { useSelector } from 'react-redux';

import { UIBTNCircle, UIDataUser, UISideBarBTNNav } from '../../ui'

const Sidebar = () => {

    const userState = useSelector(state => state.authentication)

    return (
        <>
            <input
                className="SideBar--InputBTNClose"
                type="checkbox"
                id="SideBar--InputBTNClose"
            />

            <div className="SideBar">
                <div className="SideBar__Header">
                    <img
                        onClick={() => { }}
                        alt="Logo SideBar"
                        className="SideBar__Logo"
                        src="../../../images/logoTextentreGas.svg"
                    />
                    <label for="SideBar--InputBTNClose">
                        <div className="SideBar--BodyBTNClose" >
                            <UIBTNCircle
                                ImageUP="../../../images/cross.svg"
                                ImageDown="../../../images/menu-burger.svg "
                            />
                        </div>
                    </label>
                </div>
                <UIDataUser
                    UserName={userState?.user?.n + ' ' + userState?.user?.ln}
                    UserMail=""
                    IMGUser="../../../images/IMGUser.jpg"
                />

<div>
                        Negocio
                    </div>
                    <UISideBarBTNNav
                        route="establishment"
                        label="Lista establecimientos"
                        icon="../../../images/menu-burger.svg"
                    />
                    <UISideBarBTNNav
                        route="establishment/create"
                        label="Crear establecimiento"
                        icon="../../../images/menu-burger.svg"
                    />
                    <UISideBarBTNNav
                        route="emission-point"
                        label="Lista punto de emision"
                        icon="../../../images/menu-burger.svg"
                    />
                    <UISideBarBTNNav
                        route="emission-point/create"
                        label="Crear punto de emision"
                        icon="../../../images/menu-burger.svg"
                    />
                   
                <div className="SideBar__NAV">
                    <div>
                        Seccion de usuarios
                    </div>
                    <UISideBarBTNNav
                        route="user"
                        label="Usuarios"
                        icon="../../../images/menu-burger.svg"
                    />

                    <UISideBarBTNNav
                        route="user/create"
                        label="Crear transportista"
                        icon="../../../images/menu-burger.svg"
                    />
                    <UISideBarBTNNav
                        route="user/my-data"
                        label="Mis Datos"
                        icon="../../../images/menu-burger.svg"
                    />

                    <div>
                        Mis facturas
                    </div>
                    <UISideBarBTNNav
                        route="factures"
                        label="Facturas"
                        icon="../../../images/menu-burger.svg"
                    />

                    <div>
                        Mis Productos
                    </div>
                    <UISideBarBTNNav
                        route="product"
                        label="Productos"
                        icon="../../../images/menu-burger.svg"
                    />

                   
                </div>
            </div>

        </>
    );
};

export default Sidebar;