import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';

//css
import "./index.scss";

import RootPages from './pages/rootpages';
import store from './redux/store';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
      />

      <Provider store={store}>
        <RootPages />
      </Provider>

    </BrowserRouter>
  </React.StrictMode>
);
