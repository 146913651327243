import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import './details.css';

function EditFacture(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();

    return (
        <>
            <div>EditFacture {id}</div>
            <div>{JSON.stringify(state)}</div>
        </>
    )
}

export default EditFacture