import {  NavLink, Link } from "react-router-dom";

const UISideBarBTNNav = (props) => {
  const { icon, label, route } = props;

  return (
    <>
      {/*  */}

      <NavLink
        exact
        to={route}
        className="UISideBarBTNNav"
        acctiveClassName="active"
      >
        <div className="UISideBarBTNNav__Color">
          <img src="images/arrowRight.svg" alt="" />
        </div>
        <img alt={route} src={icon} />
        <a>{label}</a>
      </NavLink>
    </>
  );
};

export default UISideBarBTNNav;
