import React from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/auth/action';

const Header = ({ currentSection, onShowNotifications }) => {
  const dispatch = useDispatch()

  return (
    <header className="header">
      <div className="header-left">
        
      </div>
      <div className="header-right">
        <button className="header-button notifications" onClick={onShowNotifications}>
          <span className="icon">🔔</span>
        </button>
        <button className="header-button logout" onClick={() => {
          dispatch(userActions.logout())
        }}>
          <span className="icon">🚪</span>
        </button>
      </div>
    </header>
  );
};

export default Header;
