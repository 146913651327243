const UISelectOptions = (props) => {
  const {label, name, value, onChange, options, required } = props;

  return (
    <div className="TextInput">
      <label>{label}</label>
      <select
        required={required || true}
        value={value}
        onChange={onChange}
        className="TextInput--Input"
        name={name}
      >
        {options &&
          options.map(item => (
            <option key={item.value} value={item.value}>{item.name}</option>
          ))}
      </select>
    </div>
  );
};

export default UISelectOptions;
