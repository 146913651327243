import { userConstants } from './types'

const initialState = { loggedIn: false, user: null };


export function authentication(state = initialState, action) {
    switch (action.type) {

      case userConstants.LOGIN_SUCCESS:
        return {
          ...state,
          loggingIn: false,
          msgErrorLogin: action.user.msgErrorLogin,
          loggedIn: action.user.loggedIn,
          user: action.user.name,
          token: action.user.token,
          userKey: action.user.userKey
        };
      case userConstants.LOGIN_FAILURE:
        return {};
      case userConstants.LOGOUT:
        return {
          loggedIn: false,
        };
  
      default:
        return state
    }
  }