import { URL_Backend } from './urlBackend';

export const apiRest = {
    login,
    register,
    statisticsFactures,
    getListUser,
    getEmisionPoints,
    registerTransport,
    getEmisionPoint,
    putEmissionPoint,
    createEmisionPoint,
    createEstablishment,
    getEstablishment,
    getEstablishments,
    putEstablishment,
    getUserData,
    saveMyData,
    getAllOrders,
    getFacture,
    getMyProducts,
    getProductsName,
    getProductsId,
    putProductsId,
    addProductData,
    saveP12

}


//ok
async function login(username, password, typeUser) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, typeUser })
    };

    const response = await fetch(`${URL_Backend}/login`, requestOptions);
    return handleResponse(response);
}


async function register(inputs) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/register`, requestOptions);
    return handleResponse(response);
}


async function statisticsFactures(id, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/statistics/${id}/factures`, requestOptions);
    return handleResponse(response);
}

async function getListUser(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/users`, requestOptions);
    return handleResponse(response);
}

async function getEmisionPoints(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/emissionpoints`, requestOptions);
    return handleResponse(response);
}

async function registerTransport(inputs, token) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/user/transport`, requestOptions);
    return handleResponse(response);
}


async function createEstablishment(inputs, token) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/establishment`, requestOptions);
    return handleResponse(response);
}

async function getEstablishments(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/establishments`, requestOptions);
    return handleResponse(response);
}

async function getEstablishment(id, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/establishment/${id}`, requestOptions);
    return handleResponse(response);
}

async function putEstablishment(id, inputs, token) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)

    };

    const response = await fetch(`${URL_Backend}/establishment/${id}`, requestOptions);
    return handleResponse(response);
}

async function createEmisionPoint(inputs, token) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/emissionpoint`, requestOptions);
    return handleResponse(response);
}

async function getEmisionPoint(id, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/emissionpoint/${id}`, requestOptions);
    return handleResponse(response);
}

async function putEmissionPoint(id, inputs, token) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/emissionpoint/${id}`, requestOptions);
    return handleResponse(response);
}

async function getUserData(id, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/user/` + id, requestOptions);
    return handleResponse(response);
}

async function saveMyData(inputs, id, token) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/user/` + id, requestOptions);
    return handleResponse(response);
}

async function getAllOrders(token, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/orders/${id}/boss/`, requestOptions);
    return handleResponse(response);
}


async function getFacture(id, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/order/${id}`, requestOptions);
    return handleResponse(response);
}


async function getMyProducts(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/products`, requestOptions);
    return handleResponse(response);
}

async function getProductsName(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/products/name`, requestOptions);
    return handleResponse(response);
}

async function getProductsId(id, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await fetch(`${URL_Backend}/product/${id}`, requestOptions);
    return handleResponse(response);
}

async function putProductsId(id, inputs, token) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/product/${id}`, requestOptions);
    return handleResponse(response);
}

async function addProductData(inputs, token) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputs)
    };

    const response = await fetch(`${URL_Backend}/products`, requestOptions);
    return handleResponse(response);
}

async function saveP12(passw, ruc, file, token) {
    let formData = new FormData();
    formData.append('passw', passw);
    formData.append('ruc', ruc);
    formData.append('fileUpload', file);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: formData
    };

    const response = await fetch(`${URL_Backend}/redirect_to/p12`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}