import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ChartFacturesMounth(props) {


    //Props
    const { className, value1 : mes, value2 : mesAnt } = props;


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pedidos comparativa con mes anterior',
            },
        },
    };

    const labels = ['Mes'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Anterior',
                data: [mes],
                backgroundColor: 'rgba(88, 18, 132, 0.5)',
            },
            {
                label: 'Actual',
                data: [mesAnt],
                backgroundColor: 'rgba(44, 66, 235, 0.5)',
            },
        ],
    };

    return (
        <div className={className}>
            <Bar options={options} data={data} />

        </div>
    )
}
export default ChartFacturesMounth