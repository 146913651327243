import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ChartFactures(props) {


    //Props
    const { className, value1 : dia, value2 : diaAnt } = props;


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pedidos comparativa con dia anterior',
            },
        },
    };

    const labels = ['Dia'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Ayer',
                data: [diaAnt],
                backgroundColor: 'rgba(188, 99, 132, 0.5)',
            },
            {
                label: 'Hoy',
                data: [dia],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <div className={className}>
            <Bar options={options} data={data} />

        </div>
    )
}
export default ChartFactures