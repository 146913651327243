import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

//api
import { apiRest } from '../../api/services'
//UI
import { UIBTNPrimary, UISelectOptions, UITextInput } from '../../ui';

function CreateEPoint() {
    const navigate = useNavigate();
    const token = useSelector(state => state.authentication.token)
    const [input, setInputs] = useState({
        name: '',
        address: '',
        telef: '',
        refEstablishment: ''
    });
    const [listEstab, setlistEstab] = useState([])

    useEffect(() => {
        apiRest
            .getEstablishments(token)
            .then((resp) => {
                let rows = resp.map((item) => (
                    {
                        id: item._id,
                        name: item.name,
                        value: item._id
                    }
                ))
                rows.unshift({name: 'Selecciona...', value: ''});
                setlistEstab(rows);
            })

    }, [token])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const createEP = (e) => {
        e.preventDefault();
        apiRest
            .createEmisionPoint(input, token)
            .then((resp) => {
                toast(resp.message, {
                    onClose: () => navigate('/admin/emission-point')
                })
                setInputs({
                    name: '',
                    address: '',
                    telef: '',
                    refEstablishment: ''
                })
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }

    return (
        <>
            <div>
                Crear Punto de Emision
            </div>
            <form name="createEP" onSubmit={createEP}>
                <UITextInput
                    name="name"
                    placeholder="Nombre"
                    value={input.name}
                    onChange={handleChange}
                />
                <UITextInput
                    name="address"
                    placeholder="Dirección del punto"
                    value={input.address}
                    onChange={handleChange}
                />
                <UITextInput
                    name="telef"
                    placeholder="Teléfono punto"
                    value={input.telef}
                    type="number"
                    onChange={handleChange}
                />

                <UISelectOptions
                    name="refEstablishment"
                    label="Establecimiento al que pertenece"
                    options={listEstab}
                    value={input.refEstablishment}
                    onChange={handleChange}
                />
                <UIBTNPrimary label="Aceptar" />
            </form>
        </>
    )
}

export default CreateEPoint
