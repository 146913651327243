import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { IconButton, Tooltip } from '@mui/material';


//API
import { apiRest } from '../../api/services';

const CustomToolbar = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('create');
  };

  return (
    <>
      <Tooltip title="Adicionar transportista">
        <IconButton onClick={handleClick}>
          Agregar
        </IconButton>
      </Tooltip>
    </>
  );
};

const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Nombres',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'creado',
    label: 'Creado',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'typeUser',
    label: 'Tipo usuario',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
  {
    name: 'nameEmissionPoint',
    label: 'Punto de emision',
    options: {
      filter: true,
      filterType: 'textField',
      sort: true,
    },
  },
]

function ListUser() {
  const token = useSelector(state => state.authentication.token);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    apiRest
      .getListUser(token)
      .then((d) => {
        let rows = d.map((item) => (
          {
            id: item?._id,
            name: item?.name + ' ' + item?.lastName,
            creado: item?.creado,
            typeUser: item?.typeUser === 1 ? 'Camión' : 'Usuario',
            nameEmissionPoint: item?.refEmissionPoint?.name
          }
        ))
        setData(rows)
      })
      .catch((e) => {
        console.log(e)
        toast(e, { type: 'error' })
      })
  }, [token])

  const options = {
    elevation: 1,
    filterType: 'checkbox',
    viewColumns: false,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 50, 100],
    selectableRows: false,
    setTableProps: () => ({
      padding: 'default',
      size: 'small',
      margin: '10px',
    }),
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
    customToolbar: () => {
      return <CustomToolbar />;
    },
    onRowClick: (rowData) => {
      navigate(`/admin/user/${rowData[0]}/edit`);
      //alert(JSON.stringify(rowData[0]))
    },
    onRowsDelete: (rowsDeleted) => {
      //  const arrayOfIds = rowsDeleted.data.map((d) => clients[d.dataIndex]._id);
      //   deleteRecords('userApp', arrayOfIds);
    },
  };

  return (
    <>
      <MUIDataTable
        title="Clientes"
        data={data}
        columns={columns}
        options={options}
      />
    </>
  )
}

export default ListUser
