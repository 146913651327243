import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { IconButton, Tooltip } from '@mui/material';

//API
import { apiRest } from '../../api/services'


const CustomToolbar = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('create');
    };

    return (
        <>
            <Tooltip title="Adicionar producto">
                <IconButton onClick={handleClick}>
                    Agregar
                </IconButton>
            </Tooltip>
        </>
    );
};

const columns = [
    {
        name: 'id',
        label: 'ID',
        options: {
            display: 'false',
            filter: false,
        },
    },
    {
        name: 'name',
        label: 'Nombre GPL',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'valorUnitario',
        label: 'Valor',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'codigoPrincipal',
        label: 'Código principal',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'impuestoPorCiento',
        label: 'Impuesto %',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'impuestoCodigo',
        label: 'ImpuestoCodigo',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    }
]

function Products() {
    const userAuth = useSelector(state => state.authentication);
    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        apiRest
            .getMyProducts(userAuth.token)
            .then((resp) => {

                let rows = resp.map((item) => (
                    {
                        id: item?._id,
                        name: item?.name?.name,
                        valorUnitario: item?.valorUnitario,
                        codigoPrincipal: item?.codigoPrincipal,
                        impuestoPorCiento: item?.impuesto?.porCiento,
                        impuestoCodigo: item?.impuesto?.codigo
                    }
                ))
                setData(rows)

            })
            .catch((e) => {
                console.log(e)
                toast(e, { type: 'error' })
            })
    }, [userAuth])

    const options = {
        elevation: 1,
        filterType: 'checkbox',
        viewColumns: false,
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 50, 100],
        selectableRows: false,
        setTableProps: () => ({
            padding: 'default',
            size: 'small',
            margin: '10px',
        }),
        sortOrder: {
            name: 'name',
            direction: 'asc',
        },
        customToolbar: () => {
            return <CustomToolbar />;
        },
        onRowClick: (rowData) => {
            navigate(`/admin/product/${rowData[0]}/edit`);
            // alert(JSON.stringify(rowData))
        },
        onRowsDelete: (rowsDeleted) => {
            //  const arrayOfIds = rowsDeleted.data.map((d) => clients[d.dataIndex]._id);
            //   deleteRecords('userApp', arrayOfIds);
        },
    };

    return (
        <>
            <MUIDataTable
                title="Productos (GAS)"
                data={data}
                columns={columns}
                options={options}

            />
        </>
    )
}

export default Products
