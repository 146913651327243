// import React from "react";
// import { BrowserRouter as Router, NavLink } from "react-router-dom";
// import "./_UITextInput.scss";

const UIBTNSecondary = (props) => {
  const { label, action, style } = props;

  return <button className="btnSecondary" onClick={() => action()} style={style}>{label}</button>;
};

export default UIBTNSecondary;
