import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';

//API
import { apiRest } from '../../api/services'

const columns = [
    {
        name: 'id',
        label: 'ID',
        options: {
            display: 'false',
            filter: false,
        },
    },
    {
        name: 'fecha',
        label: 'Fecha',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'cantidadCilindro',
        label: 'Pedido',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'tipoCilindro',
        label: 'Tipos',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'ref_user',
        label: 'Cliente',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'status',
        label: 'Estado factura',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'sri',
        label: 'Estado en SRI',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
]

function Factures() {
    const userAuth = useSelector(state => state.authentication);
    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        apiRest
            .getAllOrders(userAuth.token, userAuth.userKey)
            .then((resp) => {

                let rows = resp.map((item) => (
                    {
                        id: item._id,
                        fecha: item.fecha,
                        cantidadCilindro: item.cantidadCilindro,
                        tipoCilindro: item.tipoCilindro,
                        ref_user: item.ref_user.name,
                        status:
                            item.vista === 0 ? 'Nueva' :
                                item.vista === 1 ? 'Procesada' :
                                    'Confirmada',
                        sri: item.sri.status
                    }
                ))
                setData(rows)
            })
            .catch((e) => {
                console.log(e)
                toast(e, { type: 'error' })
            })
    }, [userAuth])

    const options = {
        elevation: 1,
        filterType: 'checkbox',
        viewColumns: false,
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 50, 100],
        selectableRows: false,
        setTableProps: () => ({
            padding: 'default',
            size: 'small',
            margin: '10px',
        }),
        sortOrder: {
            name: 'name',
            direction: 'asc',
        },
        onRowClick: (rowData) => {
            navigate(`/admin/facture/${rowData[0]}/details`);
           // alert(JSON.stringify(rowData))
        },
        onRowsDelete: (rowsDeleted) => {
            //  const arrayOfIds = rowsDeleted.data.map((d) => clients[d.dataIndex]._id);
            //   deleteRecords('userApp', arrayOfIds);
        },
    };

    return (
        <>
            <MUIDataTable
                title="Facturas"
                data={data}
                columns={columns}
                options={options}

            />
        </>
    )
}

export default Factures
