import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { apiRest } from '../../api/services';
import { UIBTNPrimary, UISelectOptions, UITextInput } from '../../ui'

function MyData() {
    const userAuth = useSelector(state => state.authentication);
    const [input, setInputs] = useState({
        name: '',
        lastName: '',
        comercialName: '',
        address: '',
        contab: '',
        movil: '',
        identification: '',
        identificationType: '',
        p12Passw: ''
    });
    let [fileUpload] = useState('');
    const [signature, setSignature] = useState(null);

    useEffect(() => {
        apiRest
            .getUserData(userAuth.userKey, userAuth.token)
            .then((res) => {
                setInputs({
                    name: res.name,
                    lastName: res.lastName,
                    identification: res?.dni?.identification,
                    identificationType: res?.dni?.identificationType,
                    comercialName: res?.comercialName,
                    address: res?.dir?.address,
                    contab: res?.contab,
                    movil: res?.movil,
                })
                setSignature(res.digitalSignature.expiration)
            })
            .catch((e) => {
                toast(e, { type: 'error' })
            })
    }, [userAuth])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs({ ...input, [name]: value });
    }

    const saveData = (e) => {
        e.preventDefault();
        apiRest
            .saveMyData(input, userAuth.userKey, userAuth.token)
            .then((res) => {
                toast(res.message)
            })
            .catch((e2) => {
                toast(e2, { type: 'error' })
            })
    }

    const sendP12 = (e) => {
        e.preventDefault();
        apiRest
            .saveP12(input.p12Passw, input.identification, fileUpload.files[0], userAuth.token)
            .then((res) => {
                toast(`Firma válida hasta el: ${res.message}`)
            })
            .catch((e2) => {
                toast(e2.message, { type: 'error' })
            })

        //   console.log(fileUpload.files[0])

    }

    const filterFile = (eve) => {
        eve.preventDefault();
        let path = eve.target.value;
        let tipoArchivos = ['p12'];
        let filename = path.replace(/^.*[\\/]/, '');

        let encontrado = tipoArchivos.filter((v) => v === filename.split('.')[1]);

        if (encontrado.length === 0) {
            fileUpload.value = '';
            toast('Error en tipo de archivo', { type: 'error' })
        }
    }

    return (
        <div>
            <h3>Datos obligatorios para facturación.</h3>
            <form name="saveDataUserWeb" onSubmit={saveData}>
                <UITextInput
                    placeholder="Nombre"
                    name="name"
                    IconSRC="/images/user_icon.svg"
                    value={input.name}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Apellidos"
                    name="lastName"
                    IconSRC="/images/user_icon.svg"
                    value={input.lastName}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Nombre comercial"
                    name="comercialName"
                    value={input.comercialName}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Dirección"
                    name="address"
                    value={input.address}
                    onChange={handleChange}
                />

                <UISelectOptions
                    name="contab"
                    label="Lleva contabilidad"
                    options={[
                        { 'name': '', 'value': '' },
                        { 'name': 'Si', 'value': 'SI' },
                        { 'name': 'No', 'value': 'NO' }
                    ]}
                    value={input.contab}
                    onChange={handleChange}
                />
                <UISelectOptions
                    name="identificationType"
                    label="Tipo de identificacion"
                    options={[
                        { 'name': '', 'value': '' },
                        { 'name': 'Pasaporte', 'value': '06' },
                        { 'name': 'Cedula', 'value': '05' },
                        { 'name': 'RUC', 'value': '04' }
                    ]}
                    value={input.identificationType}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Identificacion"
                    name="identification"
                    value={input.identification}
                    onChange={handleChange}
                />

                <UITextInput
                    placeholder="Movil"
                    name="movil"
                    value={input.movil}
                    onChange={handleChange}
                />

                <UIBTNPrimary label="Aceptar" />

            </form>

            <div>
                <h3>Fichero de firma digital</h3>
                <div>
                    Firma válida hasta el: {signature}
                </div>
                <form name="formTop12" onSubmit={sendP12}>
                    <input
                        type="file"
                        name="fileUpload"
                        required
                        ref={(ref) => { fileUpload = ref }}
                        onChange={(eve) => filterFile(eve)}
                    />

                    <UITextInput
                        placeholder="Contraseña"
                        name="p12Passw"
                        value={input.p12Passw}
                        onChange={handleChange}
                    />
                    <UIBTNPrimary label="Aceptar" />

                </form>

            </div>

        </div>
    )
}

export default MyData
