// import React from "react";
// import { BrowserRouter as Router, NavLink } from "react-router-dom";
// import "./_UITextInput.scss";

const UIBTNPrimary = (props) => {
  const { label, onClick } = props;

  return <button className="btnPrimary" onClick={onClick}>{label}</button>;
};

export default UIBTNPrimary;
