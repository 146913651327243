import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';

//api
import { apiRest } from '../../api/services';

const CustomToolbar = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('create');
    };

    return (
        <>
            <Tooltip title="Adicionar punto">
                <IconButton onClick={handleClick}>
                    Agregar
                </IconButton>
            </Tooltip>
        </>
    );
};


const columns = [
    {
        name: 'id',
        label: 'ID',
        options: {
            display: 'false',
            filter: false,
        },
    },
    {
        name: 'name',
        label: 'Nombres',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'codEmissionPoint',
        label: 'Codigo',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'telef',
        label: 'Teléfono',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
    {
        name: 'refEstablishment',
        label: 'Establecimiento',
        options: {
            filter: true,
            filterType: 'textField',
            sort: true,
        },
    },
]

function EmissionPoint() {
    const [data, setData] = useState([]);
    const token = useSelector(state => state.authentication.token)
    const navigate = useNavigate();

    useEffect(() => {
        apiRest
            .getEmisionPoints(token)
            .then((resp) => {
                console.log(resp)
                let rows = resp.map((item) => (
                    {
                        id: item?._id,
                        name: item?.name,
                        codEmissionPoint: item?.codEmissionPoint,
                        telef: item?.telef,
                        refEstablishment: item?.refEstablishment?.name
                    }
                ))
                setData(rows);
            })
            .catch((er) => {
                toast(er, { type: 'error' })
            })
    }, [token])

    const options = {
        elevation: 1,
        filterType: 'checkbox',
        viewColumns: false,
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 50, 100],
        selectableRows: false,
        setTableProps: () => ({
            padding: 'default',
            size: 'small',
            margin: '10px',
        }),
        sortOrder: {
            name: 'name',
            direction: 'asc',
        },
        customToolbar: () => {
            return <CustomToolbar />;
        },
        onRowClick: (rowData) => {
            navigate(`/admin/emission-point/${rowData[0]}/edit/`);
            //alert(JSON.stringify(rowData))
        },
        onRowsDelete: (rowsDeleted) => {
            //  const arrayOfIds = rowsDeleted.data.map((d) => clients[d.dataIndex]._id);
            //   deleteRecords('userApp', arrayOfIds);
        },
    };

    return (
        <>
            <MUIDataTable
                title="Puntos de emisión"
                data={data}
                columns={columns}
                options={options}

            />
        </>
    )
}

export default EmissionPoint
